<script>
import Layout from "../../layouts/auth";
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import { mapState } from "vuex";

import appConfig from "@/app.config";
import {
  required,
  //email,
  helpers,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { Api } from "../../../helpers";
import Swal from "sweetalert2";

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout },
  data() {
    return {
      process: false,
      user: {},
      roles: [],
      roleUser: "",
      email: "",
      password: "",
      username: "",
      telephone: "",
      connectTo: "username",
      passwordType: "password",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email is required", required),
      //email: helpers.withMessage("Please enter valid email", email),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
    telephone: {
      required: helpers.withMessage("Phone is required", required),
    },
    username: {
      required: helpers.withMessage("Username is required", required),
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    showPassword() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    connectEmail() {
      console.log("email");
      this.connectTo = "email";
    },
    connectUsername() {
      console.log("username");
      this.connectTo = "username";
    },
    connectPhone() {
      console.log("phone");
      this.connectTo = "telephone";
    },
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      // this.submitted = true;
      // // stop here if form is invalid
      // this.v$.$touch();

      // if (this.v$.$invalid) {
      //   return;
      // } else {
      // if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
      //   this.tryingToLogIn = true;
      //   // Reset the authError if it existed.
      //   this.authError = null;
      //   return (
      //     this.logIn({
      //       email: this.email,
      //       password: this.password,
      //     })
      //       // eslint-disable-next-line no-unused-vars
      //       .then((token) => {
      //         this.tryingToLogIn = false;
      //         this.isAuthError = false;
      //         // Redirect to the originally requested page, or to the home page
      //         this.$router.push(this.$route.query.redirectFrom || {name: "dashboard"});
      //       })
      //       .catch((error) => {
      //         this.tryingToLogIn = false;
      //         this.authError = error ? error : "";
      //         this.isAuthError = true;
      //       })
      //   );
      // } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
      //   const {email, password} = this;
      //   if (email && password) {
      //     this.login({email, password})
      //   }
      // } else if (process.env.VUE_APP_DEFAULT_AUTH === "authapi") {
      this.process = true
      if (this.connectTo == "username") {
        Api.post("/authentication/api/auth/signin-username", {
          username: this.username,
          password: this.password,
        })
          .then((res) => {
            console.log(res);
            this.user = res.data;
            this.roles = this.user.roles;
            console.log(this.roles);

            if (this.roles.includes("ROLE_ADMIN") || this.roles.includes("ROLE_AWARD") || this.roles.includes("ROLE_CREATOR")) {
              if (this.user.token) {
                localStorage.setItem("user", JSON.stringify(this.user));
                localStorage.setItem("jwtToken", this.user.token);
              }

              if (this.roles.includes("ROLE_ADMIN") && this.roles.includes("ROLE_CREATOR") && this.roles.includes("ROLE_AWARD")) {
                this.$router.push({ name: "dashboard" });
              } else {
                if (this.roles.includes("ROLE_AWARD")) {
                  this.$router.push({ name: "award-list" });
                }
                if (this.roles.includes("ROLE_CREATOR")) {
                  this.$router.push({ name: "Videos-list" });
                }
                if (this.roles.includes("ROLE_ADMIN")) {
                  this.$router.push({ name: "dashboard" });
                }
              }
            } else {
              this.process = false
              Swal.fire("Désolé!", "Vous n'avez pas les accès", "warning");
            }
          })
          .catch((error) => {
            console.log(error);
            this.process = false
            this.tryingToLogIn = false;
            if (error.message == "Request failed with status code 404") {
              this.authError = "Chemin introuvable! ";
            }
            if (error.message == "Request failed with status code 500") {
              this.authError = "Veuillez rafraichir la page!";
            }
            if (error.message == "Request failed with status code 401") {
              this.authError = "Identifiant ou mot de passe incorectes!";
            }
            if (error.message == "Request failed with status code 400") {
              this.authError = "Veuillez remplir tous les champs!";
            }
            if (error.message == "Network Error") {
              this.authError = "Vérifier votre connexion internet!";
            }
            this.isAuthError = true;
          });
      }

      if (this.connectTo == "email") {
        Api.post("/authentication/api/auth/signin-email", {
          email: this.email,
          password: this.password,
        })
          .then((res) => {
            console.log(res);
            this.user = res.data;
            this.roles = this.user.roles;
            console.log(this.roles);

            if (this.roles.includes("ROLE_ADMIN") || this.roles.includes("ROLE_AWARD") || this.roles.includes("ROLE_CREATOR")) {

              if (this.user.token) {
                localStorage.setItem("user", JSON.stringify(this.user));
                localStorage.setItem("jwtToken", this.user.token);
              }
              if (this.roles.includes("ROLE_ADMIN") && this.roles.includes("ROLE_CREATOR") && this.roles.includes("ROLE_AWARD")) {
                this.$router.push({ name: "dashboard" });
              } else {
                if (this.roles.includes("ROLE_AWARD")) {
                  this.$router.push({ name: "award-list" });
                }
                if (this.roles.includes("ROLE_CREATOR")) {
                  this.$router.push({ name: "Videos-list" });
                }
                if (this.roles.includes("ROLE_ADMIN")) {
                  this.$router.push({ name: "dashboard" });
                }
              }

            } else {
              this.process = false
              Swal.fire("Erreur!", "Désolé vous n'etes pas autorisé", "warning");
            }
          })
          .catch((error) => {
            console.log(error);
            this.process = false
            this.tryingToLogIn = false;
            if (error.message == "Request failed with status code 404") {
              this.authError = "Chemin introuvable! ";
            }
            if (error.message == "Request failed with status code 500") {
              this.authError = "Veuillez rafraichir la page!";
            }
            if (error.message == "Request failed with status code 401") {
              this.authError = "Identifiant ou mot de passe incorectes!";
            }
            if (error.message == "Request failed with status code 400") {
              this.authError = "Veuillez remplir tous les champs!";
            }
            if (error.message == "Network Error") {
              this.authError = "Vérifier votre connexion internet!";
            }
            this.isAuthError = true;
          });
      }

      if (this.connectTo == "telephone") {
        Api.post("/authentication/api/auth/signin-phone", {
          telephone: this.telephone,
          password: this.password,
        })
          .then((res) => {
            console.log(res);
            this.user = res.data;
            this.roles = this.user.roles;
            console.log(this.roles);

            if (this.roles.includes("ROLE_ADMIN") || this.roles.includes("ROLE_AWARD") || this.roles.includes("ROLE_CREATOR")) {
              if (this.user.token) {
                localStorage.setItem("user", JSON.stringify(this.user));
                localStorage.setItem("jwtToken", this.user.token);
              }

              if (this.roles.includes("ROLE_ADMIN") && this.roles.includes("ROLE_CREATOR") && this.roles.includes("ROLE_AWARD")) {
                this.$router.push({ name: "dashboard" });
              } else {
                if (this.roles.includes("ROLE_AWARD")) {
                  this.$router.push({ name: "award-list" });
                }
                if (this.roles.includes("ROLE_CREATOR")) {
                  this.$router.push({ name: "Videos-list" });
                }
                if (this.roles.includes("ROLE_ADMIN")) {
                  this.$router.push({ name: "dashboard" });
                }
              }

            } else {
              this.process = false
              Swal.fire("Erreur!", "Désolé vous n'etes pas autorisé", "warning");
            }
          })
          .catch((error) => {
            this.process = false
            console.log(error);
            this.tryingToLogIn = false;
            if (error.message == "Request failed with status code 404") {
              this.authError = "Chemin introuvable! ";
            }
            if (error.message == "Request failed with status code 500") {
              this.authError = "Veuillez rafraichir la page!";
            }
            if (error.message == "Request failed with status code 401") {
              this.authError = "Identifiant ou mot de passe incorectes!";
            }
            if (error.message == "Request failed with status code 400") {
              this.authError = "Veuillez remplir tous les champs!";
            }
            if (error.message == "Network Error") {
              this.authError = "Vérifier votre connexion internet!";
            }
            this.isAuthError = true;
          });
      }

      // }
      // }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">{{ $t("pages.login.title") }}</h5>
                  <p>{{ $t("pages.login.hint") }}</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <!-- <router-link to="/dashboard"> -->
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/logos/logo-2play-en-couleur.png" alt height="80" />
                </span>
              </div>
              <!-- </router-link> -->
            </div>
            <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>
              {{ authError }}
            </b-alert>
            <div v-if="notification.message" :class="'alert ' + notification.type">
              {{ notification.message }}
            </div>

            <b-form class="p-2" @submit="tryToLogIn">
              <b-tabs justified content-class="p-3 text-muted">
                <b-tab active class="border-0" @click="connectUsername">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Username</span>
                  </template>
                  <b-form-group class="mb-3" id="input-group-1" :label="$t('pages.login.login.label')"
                    label-for="input-1">
                    <b-form-input id="input-1" type="text" :placeholder="$t('pages.login.login.placeholder')"
                      :class="{ 'is-invalid': submitted && v$.email.$error }" v-model="username"></b-form-input>
                    <div v-for="(item, index) in v$.username.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </b-form-group>
                  <b-form-group class="mb-3" id="input-group-2" label-for="input-2"
                    :label="$t('pages.login.password.label')">
                    <b-form-input id="input-2" :type="passwordType" :placeholder="$t('pages.login.password.placeholder')"
                      :class="{ 'is-invalid': submitted && v$.password.$error }" v-model="password"></b-form-input>
                    <div v-if="submitted && v$.password.$error" class="invalid-feedback">
                      <span v-if="v$.password.required.$message">{{
                        v$.password.required.$message
                      }}</span>
                    </div>
                  </b-form-group>
                </b-tab>
                <b-tab @click="connectEmail">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Email</span>
                  </template>
                  <b-form-group class="mb-3" id="input-group-1" :label="$t('pages.login.login.label')"
                    label-for="input-1">
                    <b-form-input id="input-1" type="text" :placeholder="$t('pages.login.login.placeholder')"
                      :class="{ 'is-invalid': submitted && v$.email.$error }" v-model="email"></b-form-input>
                    <div v-for="(item, index) in v$.email.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </b-form-group>
                  <b-form-group class="mb-3" id="input-group-2" label-for="input-2"
                    :label="$t('pages.login.password.label')">
                    <b-form-input id="input-2" :type="passwordType" :placeholder="$t('pages.login.password.placeholder')"
                      :class="{ 'is-invalid': submitted && v$.password.$error }" v-model="password"></b-form-input>
                    <div v-if="submitted && v$.password.$error" class="invalid-feedback">
                      <span v-if="v$.password.required.$message">{{
                        v$.password.required.$message
                      }}</span>
                    </div>
                  </b-form-group>
                </b-tab>
                <b-tab @click="connectPhone">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-envelope"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Telephone</span>
                  </template>
                  <b-form-group class="mb-3" id="input-group-1" :label="$t('pages.login.login.label')"
                    label-for="input-1">
                    <b-form-input id="input-1" type="text" :placeholder="$t('pages.login.login.placeholder')"
                      :class="{ 'is-invalid': submitted && v$.email.$error }" v-model="telephone"></b-form-input>
                    <div v-for="(item, index) in v$.telephone.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </b-form-group>
                  <b-form-group class="mb-3" id="input-group-2" label-for="input-2"
                    :label="$t('pages.login.password.label')">
                    <b-form-input id="input-2" :type="passwordType" :placeholder="$t('pages.login.password.placeholder')"
                      :class="{ 'is-invalid': submitted && v$.password.$error }" v-model="password"></b-form-input>
                    <div v-if="submitted && v$.password.$error" class="invalid-feedback">
                      <span v-if="v$.password.required.$message">{{
                        v$.password.required.$message
                      }}</span>
                    </div>
                  </b-form-group>
                </b-tab>
              </b-tabs>

              <b-form-checkbox class="form-check me-2 mt-0" id="customControlInline" name="checkbox-1" value="accepted"
                unchecked-value="not_accepted" @click="showPassword">
                {{ $t("pages.login.remember.text") }}
              </b-form-checkbox>
              <div class="mt-3 d-grid">
                <b-button type="submit" class="btn-block c2play-primary">
                  <span v-if="process == false">{{ $t("pages.login.submitBtn.text") }}</span>
                  <b-spinner v-else variant="primary" role="status"></b-spinner>
                </b-button>
              </div>

              <!--              <div class="mt-4 text-center">-->
              <!--                <h5 class="font-size-14 mb-3">Sign in with</h5>-->

              <!--                <ul class="list-inline">-->
              <!--                  <li class="list-inline-item">-->
              <!--                    <a-->
              <!--                      href="javascript: void(0);"-->
              <!--                      class="-->
              <!--                        social-list-item-->
              <!--                        bg-primary-->
              <!--                        text-white-->
              <!--                        border-primary-->
              <!--                      "-->
              <!--                    >-->
              <!--                      <i class="mdi mdi-facebook"></i>-->
              <!--                    </a>-->
              <!--                  </li>-->
              <!--                  <li class="list-inline-item">-->
              <!--                    <a-->
              <!--                      href="javascript: void(0);"-->
              <!--                      class="social-list-item bg-info text-white border-info"-->
              <!--                    >-->
              <!--                      <i class="mdi mdi-twitter"></i>-->
              <!--                    </a>-->
              <!--                  </li>-->
              <!--                  <li class="list-inline-item">-->
              <!--                    <a-->
              <!--                      href="javascript: void(0);"-->
              <!--                      class="-->
              <!--                        social-list-item-->
              <!--                        bg-danger-->
              <!--                        text-white-->
              <!--                        border-danger-->
              <!--                      "-->
              <!--                    >-->
              <!--                      <i class="mdi mdi-google"></i>-->
              <!--                    </a>-->
              <!--                  </li>-->
              <!--                </ul>-->
              <!--              </div>-->
              <!--              <div class="mt-4 text-center">-->
              <!--                <router-link to="/forgot-password" class="text-muted">-->
              <!--                  <i class="mdi mdi-lock me-1"></i> Forgot your password?-->
              <!--                </router-link>-->
              <!--              </div>-->
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
        <div class="text-center">
          <p>{{ $t("footer.copyright") }}</p>
          <p>
            By
            <a href="https://www.digitalhouse-int.com/" target="_blank">Digital House International</a>
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
